import React from "react";
import { Contract, WalletConnection } from "near-api-js";

interface IContext {
  contract: Contract | undefined;
  currentUser:
    | {
        accountId: any;
        balance: string;
      }
    | undefined;
  nearConfig: any;
  wallet: WalletConnection | undefined;
}
const contextDefaultVal: IContext = {
  contract: undefined,
  currentUser: undefined,
  nearConfig: undefined,
  wallet: undefined,
};

const NEARContext = React.createContext<IContext>(contextDefaultVal);

export default NEARContext;
