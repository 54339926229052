import { IValidationRequest } from "./../model/index";
import makeRequest from "config/utils/makeRequest";
import { thunk, action } from "easy-peasy";
import { IValidationRequestModel } from "store/model";

const basePath = "/validation";

export const validation: IValidationRequestModel = {
  loading: false,
  item: null,
  items: [],
  getItem: thunk(async (actions, id) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { validationRequest },
        },
      } = await makeRequest("GET", basePath + `/${id}`);

      actions.saveItem(validationRequest as IValidationRequest);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),
  getItems: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { validationRequests },
        },
      } = await makeRequest("GET", basePath + "/");

      actions.saveItems(validationRequests);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),
  softUpdateItems: thunk(async (actions) => {
    try {
      const {
        data: {
          data: { validationRequests },
        },
      } = await makeRequest("GET", basePath + "/");

      actions.saveItems(validationRequests);
    } catch (error) {
    } 
  }),
  requestValidation: thunk(async (actions, id) => {
    try {
      await makeRequest("POST", basePath + "/" + id);
    } catch (error) {
    } 
  }),

  mintRequest: thunk(async (actions, id) => {
    actions.setLoading(true);
    try {
      await makeRequest("PATCH", basePath + "/mint/" + id);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  validateRequest: thunk(async (actions, id) => {
    actions.setLoading(true);
    try {
      await makeRequest("PATCH", basePath + "/validate/" + id);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  getItemsByStatus: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { validationRequests },
        },
      } = await makeRequest("GET", basePath + "/" + payload);

      actions.saveItems(validationRequests);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  saveItem: action((state, payload) => {
    state.item = payload;
  }),

  saveItems: action((state, payload) => {
    state.items = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
};
