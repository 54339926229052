import { Container, Flex, Grid, } from "components/jss";
import Text from "components/Typography";
import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgb(4, 184, 233);
  background: linear-gradient(43deg, rgba(4, 184, 233, 1) 8%, rgba(38, 222, 231, 1) 35%, rgba(107, 233, 235, 1) 49%, rgba(4, 184, 233, 1) 91%);
  .logo {
    max-width: 160px;
  }
`;

const WrapperInner = styled.div`
  max-width: 1800px;
  margin: auto;
`;

const WrapperBg = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-image: url("/images/icon/bg.png");
  background-repeat: no-repeat;
  background-blend-mode: screen;
  background-size: 40%;
  background-position: bottom right;
`;

const DataBox = styled.div`
  border: 4px solid #fff;
  position: relative;
  padding: 40px 40px 40px 90px;
  display: flex;
  align-items: center;
  margin-left: 100px;
  .icon {
    position: absolute;
    width: 160px;
    left: -80px;
    .icon-1 {
      display: block;
    }
    .icon-2 {
      display: none;
    }
    img {
      width: 100%;
    }
  }

  &.even {
    margin-left: 0;
    margin-right: 100px;
    padding: 40px 90px 40px 40px;
    .icon {
      position: absolute;
      left: unset;
      right: -80px;
    }
  }
  ${({ theme }) => theme.mediaQueries.max.sm} {
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    margin-left: 0;
    &.even {
      padding: 20px;
      margin-right: 0;
      .icon {
        width: 80px;
        position: relative;
        left: unset;
        right: unset;
        margin-bottom: 10px;
        .icon-2 {
          display: block;
        }
        .icon-1 {
          display: none;
        }
      }
    }
    .icon {
      width: 80px;
      position: relative;
      left: unset;
      right: unset;
      margin-bottom: 10px;
      .icon-2 {
        display: block;
      }
      .icon-1 {
        display: none;
      }
    }
  }
`;

const Cube = styled.div`
  width: 20px;
  height: 20px;
  background-color: #fff;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0px;
  }

  ${({ theme }) => theme.mediaQueries.max.sm} {
    width: 15px;
    height: 15px;
    margin-bottom: 10px;
  }
`;

const Cubes = () => (
  <Flex marginYaxis="40px" marginSmYaxis="20px" justifyContent="center" alignItems="center" flexDirection="column">
    <Cube />
    <Cube />
    <Cube />
  </Flex>
);

export default function LandingPage() {
  return (
    <Wrapper>
      <WrapperBg>
        <WrapperInner>
          <Container paddingXaxis="40px" paddingTop="60px">
            <Flex marginBottom="80px" justifyContent="center" alignItems="center">
              <img className="logo" src="/images/icon/logo.png" alt="logo" />
              <Text align="center" color="white" fontWeight={700} fontSize="xxl">
                NIFTY <br /> TALENT
              </Text>
            </Flex>

            <Grid margin="auto">
              <Text align="center" color="white" fontWeight={700} fontSize="xxl">
                Unlock the benefits of <br /> owning your validated <br /> professional identity
              </Text>
            </Grid>
          </Container>

          <Container paddingXaxis="40px" paddingTop="80px" paddingBottom="120px">
            <Grid margin="auto" md={9} lg={8} xl={5}>
              <DataBox>
                <div className="icon">
                  <img className="icon-1" src="/images/icon/logo-data.png" alt="" />
                  <img className="icon-2" src="/images/icon/logo.png" alt="" />
                </div>
                <Text color="white" fontSize="lg">
                  Nifty Talent is creating a robust blockchain agnostic Web3 platform where you can own and leverage your professional identity. Experience the
                  next evolution of job seeking and hiring through validated work history NFTs.
                </Text>
              </DataBox>
              <Cubes />
              <DataBox className="even">
                <div className="icon">
                  <img src="/images/icon/resume-icon.png" alt="" />
                </div>
                <Text color="white" fontSize="lg">
                  Take ownership of all your previous hard work and stand out to potential employers. Nifty Talent provides you with the tools required to
                  showcase your validated professional portfolio and enables you to earn while doing so.
                </Text>
              </DataBox>
              <Cubes />
              <DataBox>
                <div className="icon">
                  <img src="/images/icon/search-icon.png" alt="" />
                </div>
                <Text color="white" fontSize="lg">
                  Employers can simplify the hiring process, automate contract negotiations, and streamline payroll all within Nifty Talent platform. Browse,
                  search, and advertise your job oppurtunities to validate applicants. Save time, money, and your company's reputation by hiring validated
                  talent.
                </Text>
              </DataBox>

              <Cubes />

              <Text align="center" color="white" fontSize="lg">
                Follow us for news and launch updates
              </Text>

              <Flex justifyContent="center" marginTop="20px" alignItems="center">
                <a style={{ marginRight: 20 }} href="https://twitter.com/nifty_talent" target="_blank"  rel="noreferrer">
                  <img src="/images/icon/twitter.png" width={30} alt="twitter" />
                </a>
                <a style={{ marginRight: 20 }} href="https://www.instagram.com/nifty_talent/" target="_blank"  rel="noreferrer">
                  <img src="/images/icon/instagram.png" width={30} alt="instagram" />
                </a>
                <a style={{ marginRight: 20 }} href="https://www.linkedin.com/company/niftytalent/" target="_blank"  rel="noreferrer">
                  <img src="/images/icon/linkedIn.png" width={30} alt="linkedin" />
                </a>
                <a href="/" role="button" target="_blank"  rel="noreferrer">
                  <img src="/images/icon/facebook.png" width={30} alt="facebook" />
                </a>
              </Flex>
            </Grid>
          </Container>
        </WrapperInner>
      </WrapperBg>
    </Wrapper>
  );
}
