import { ALIGN_CONTENT, FLEX, FLEX_DIRECTION, FLEX_WRAP, JUSTIFY_CONTENT } from "css-properties-props";
import styled from "styled-components";
import { SpacingStyle } from "./spacing";
import { ColProps } from "./types";

interface Props {
  flexWrap?: FLEX_WRAP;
  alignItems?: ALIGN_CONTENT;
  justifyContent?: JUSTIFY_CONTENT;
  flexDirection?: FLEX_DIRECTION;

  // 1400px
  flexWrapXl?: FLEX_WRAP;
  alignItemsXl?: ALIGN_CONTENT;
  justifyContentXl?: JUSTIFY_CONTENT;
  flexDirectionXl?: FLEX_DIRECTION;

  // 968px
  flexWrapLg?: FLEX_WRAP;
  alignItemsLg?: ALIGN_CONTENT;
  justifyContentLg?: JUSTIFY_CONTENT;
  flexDirectionLg?: FLEX_DIRECTION;

  //  852px
  flexWrapMd?: FLEX_WRAP;
  alignItemsMd?: ALIGN_CONTENT;
  justifyContentMd?: JUSTIFY_CONTENT;
  flexDirectionMd?: FLEX_DIRECTION;

  // 576px
  flexWrapSm?: FLEX_WRAP;
  alignItemsSm?: ALIGN_CONTENT;
  justifyContentSm?: JUSTIFY_CONTENT;
  flexDirectionSm?: FLEX_DIRECTION;

  // 370px
  flexWrapXs?: FLEX_WRAP;
  alignItemsXs?: ALIGN_CONTENT;
  justifyContentXs?: JUSTIFY_CONTENT;
  flexDirectionXs?: FLEX_DIRECTION;

  // Child Flex
  itemsFlex?: FLEX;
  itemsFlexXl?: FLEX;
  itemsFlexLg?: FLEX;
  itemsFlexMd?: FLEX;
  itemsFlexSm?: FLEX;
  itemsFlexXs?: FLEX;

  // parent fles
  flex?: FLEX;
  flexXl?: FLEX;
  flexLg?: FLEX;
  flexMd?: FLEX;
  flexSm?: FLEX;
  flexXs?: FLEX;
}

const FlexStyle = styled(SpacingStyle)<Props>`
  width: 100%;
  display: flex;
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-direction: ${({ flexDirection }) => flexDirection || "row"};
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: ${({ alignItems }) => alignItems || "flex-start"};
  flex: ${({ flex }) => flex || "unset"};
  > * {
    flex: ${({ itemsFlex }) => itemsFlex};
  }

  ${({ theme }) => theme.mediaQueries.max.xl} {
    flex: ${({ flexXl }) => flexXl || "unset"};
    flex-direction: ${({ flexDirectionXl }) => flexDirectionXl};
    justify-content: ${({ justifyContentXl }) => justifyContentXl};
    align-items: ${({ alignItemsXl }) => alignItemsXl};
    flex-wrap: ${({ flexWrapXl }) => flexWrapXl};
    > * {
      flex: ${({ itemsFlexXl }) => itemsFlexXl};
    }
  }

  ${({ theme }) => theme.mediaQueries.max.lg} {
    flex: ${({ flexLg }) => flexLg || "unset"};
    flex-direction: ${({ flexDirectionLg }) => flexDirectionLg};
    justify-content: ${({ justifyContentLg }) => justifyContentLg};
    align-items: ${({ alignItemsLg }) => alignItemsLg};
    flex-wrap: ${({ flexWrapLg }) => flexWrapLg};
    > * {
      flex: ${({ itemsFlexLg }) => itemsFlexLg};
    }
  }
  ${({ theme }) => theme.mediaQueries.max.md} {
    flex: ${({ flexMd }) => flexMd || "unset"};
    flex-direction: ${({ flexDirectionMd }) => flexDirectionMd};
    justify-content: ${({ justifyContentMd }) => justifyContentMd};
    align-items: ${({ alignItemsMd }) => alignItemsMd};
    flex-wrap: ${({ flexWrapMd }) => flexWrapMd};
    > * {
      flex: ${({ itemsFlexMd }) => itemsFlexMd};
    }
  }
  ${({ theme }) => theme.mediaQueries.max.sm} {
    flex: ${({ flexSm }) => flexSm || "unset"};
    flex-direction: ${({ flexDirectionSm }) => flexDirectionSm};
    justify-content: ${({ justifyContentSm }) => justifyContentSm};
    align-items: ${({ alignItemsSm }) => alignItemsSm};
    flex-wrap: ${({ flexWrapSm }) => flexWrapSm};
    > * {
      flex: ${({ itemsFlexSm }) => itemsFlexSm};
    }
  }

  ${({ theme }) => theme.mediaQueries.max.xs} {
    flex: ${({ flexXs }) => flexXs || "unset"};
    flex-direction: ${({ flexDirectionXs }) => flexDirectionXs};
    justify-content: ${({ justifyContentXs }) => justifyContentXs};
    align-items: ${({ alignItemsXs }) => alignItemsXs};
    flex-wrap: ${({ flexWrapXs }) => flexWrapXs};
    > * {
      flex: ${({ itemsFlexXs }) => itemsFlexXs};
    }
  }
`;

export const ColStyles = styled(SpacingStyle)<ColProps>`
  width: ${({ col }) => (col ? `${(col * 100) / 12}%` : "100%")};

  ${({ theme }) => theme.mediaQueries.min.xl} {
    width: ${({ xl }) => xl && `${(xl * 100) / 12}%`};
  }

  ${({ theme }) => theme.mediaQueries.min.lg} {
    width: ${({ lg }) => lg && `${(lg * 100) / 12}%`};
  }

  ${({ theme }) => theme.mediaQueries.min.md} {
    width: ${({ md }) => md && `${(md * 100) / 12}%`};
  }

  ${({ theme }) => theme.mediaQueries.min.sm} {
    width: ${({ sm }) => sm && `${(sm * 100) / 12}%`};
  }

  ${({ theme }) => theme.mediaQueries.min.xs} {
    width: ${({ xs }) => xs && `${(xs * 100) / 12}%`};
  }
`;

export default FlexStyle;
