import { DefaultTheme } from "styled-components";
import { Breakpoints, Colors, FontSizes, LineHeights, MediaQueries, Radii, Shadows, ZIndices } from "./types";

export const breakpointMap: { [key: string]: number } = {
  xs: 370,
  sm: 576,
  md: 852,
  lg: 968,
  xl: 1400,
};

const breakpoints: Breakpoints = Object.values(breakpointMap).map((breakpoint) => `${breakpoint}px`);

const mediaQueries: MediaQueries = {
  min: {
    xs: `@media all and (min-width: ${breakpointMap.xs}px)`,
    sm: `@media all and (min-width: ${breakpointMap.sm}px)`,
    md: `@media all and (min-width: ${breakpointMap.md}px)`,
    lg: `@media all and (min-width: ${breakpointMap.lg}px)`,
    xl: `@media all and (min-width: ${breakpointMap.xl}px)`,
    nav: `@media all and (min-width: ${breakpointMap.lg}px)`,
  },
  max: {
    xs: `@media all and (max-width: ${breakpointMap.xs}px)`,
    sm: `@media all and (max-width: ${breakpointMap.sm}px)`,
    md: `@media all and (max-width: ${breakpointMap.md}px)`,
    lg: `@media all and (max-width: ${breakpointMap.lg}px)`,
    xl: `@media all and (max-width: ${breakpointMap.xl}px)`,
    nav: `@media all and (max-width: ${breakpointMap.lg}px)`,
  },
};

const fontSizes: FontSizes = {
  xs: "14px",
  sm: "16px",
  md: "18px",
  lg: "20px",
  xl: "24px",
  xxl: "36px",
  xxxl: "64px",
  screenXl: {
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg: "20px",
    xl: "24px",
    xxl: "28px",
    xxxl: "48px",
  },
  screenMd: {
    xs: "13px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    xxl: "24px",
    xxxl: "28px",
  },
};

const lineHeights: LineHeights = {
  xs: "18px",
  sm: "20px",
  md: "22px",
  lg: "24px",
  xl: "28px",
  xxl: "40px",
  xxxl: "68px",
  screenXl: {
    xs: "18px",
    sm: "20px",
    md: "22px",
    lg: "24px",
    xl: "28px",
    xxl: "32px",
    xxxl: "52px",
  },
  screenMd: {
    xs: "17px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
    xxl: "28px",
    xxxl: "32px",
  },
};

const radius: Radii = {
  card: "10px",
  small: "5px",
  circle: "50%",
  default: "50px",
  rounded: "20px",
};

const shadows: Shadows = {
  level1: "0px 12px 25px 4px rgba(6,107,149,0.45)",
  level2: "0px 3px 6px 2px rgba(6,107,149,0.15)",

};

const colors: Colors = {
  primary: "#875FF8",
  primaryDark: "#6832FF",
  secondary: "#74DC7F",
  secondaryLight: "rgba(116, 220, 127, 0.7);",
  tertiary: "#1E88E5",
  white: "#FFFFFF",
  white2: "dfdfdf",
  grey: "#707070",
  lightGrey: "rgba(112,112,112,.10)",
  gray: "#0000001F",
  warning: "#EDF505",
  success: "#00FF6F",
  danger: "#993939",
  black: "#000000",
  text: "#252525",
  textSubtle: "rgba(37,37,37,.60)",
  textDisabled: "rgba(112,112,112,.60)",
  background: "#FFFFFF",
  input: "#fff",
  inputSecondary: "#C9BFBF",
  backgroundAlt: "",
  borderColor: "#707070",
  darkGrey: "#5A5757",
};

const zIndices: ZIndices = {
  dropdown: 2000,
  modal: 4000,
};

const theme: DefaultTheme = {
  mediaQueries,
  breakpoints,
  colors,
  fontSizes,
  radius,
  shadows,
  zIndices,
  lineHeights,
};

export default theme;
