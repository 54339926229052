import styled from "styled-components";
import { QueryProps } from "../../config/theme/types";

export const HideUpStyle = styled.div<{ query: QueryProps }>`
  ${({ theme, query }) => theme.mediaQueries.min[query]} {
    display: none !important;
  }
`;

export const HideDownStyle = styled.div<{ query: QueryProps }>`
  ${({ theme, query }) => theme.mediaQueries.max[query]} {
    display: none !important;
  }
`;
