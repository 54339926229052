import { ThemeProvider } from "styled-components";
import theme from "config/theme";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import HomePage from "pages/Home";
//  
import LandingPage from "pages/LandingPage";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          {/* <Route path="/app" element={<MainPage />} />
          <Route path="/app/info" element={<InfoUpdate />} />
          <Route
            path="/admin/"
            element={
              <PrivateRoute restrict={["admin"]}>
                <AdminHome />
              </PrivateRoute>
            }
          />
          <Route
            path="/admin/request/:id"
            element={
              <PrivateRoute restrict={["admin"]}>
                <HistoryItem />
              </PrivateRoute>
            }
          />
          <Route
            path="/app/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
