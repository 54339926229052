import { ACCOUNT_ID_KEY } from 'config/constants';
import axios, { Method } from "axios";
// import { } from "near-api-js";

const api = process.env.REACT_APP_API_URL;

export default function makeRequest(method: Method, path: string, body?: any) {
  return axios({
    url: api + path,
    method,
    data: body,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(ACCOUNT_ID_KEY)}`,
    },
  });
}
