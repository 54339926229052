import { ACCOUNT_ID_KEY } from 'config/constants/index';
import getConfig from "./config";
import * as nearAPI from "near-api-js";

export interface IWindow extends Window {
  nearInitPromise: any;
}

// Initializing contract
const initContract = async () => {
  const nearConfig = getConfig(process.env.NODE_ENV || "testnet");

  // Initializing connection to the NEAR TestNet
  const near = await nearAPI.connect({
    deps: {
      keyStore: new nearAPI.keyStores.BrowserLocalStorageKeyStore(),
    },
    ...nearConfig,
    headers: {},
  });

  // Needed to access wallet
  const wallet = new nearAPI.WalletConnection(near, "Nifty Talent");
  // Load in account data
  let currentUser;
  if (wallet.getAccountId()) {
    localStorage.setItem(ACCOUNT_ID_KEY, wallet.getAccountId());

    currentUser = {
      accountId: wallet.getAccountId(),
      balance: (await wallet.account().state()).amount,
    };
  }

  // Initializing our contract APIs with contract name and configuration
  const contract = new nearAPI.Contract(wallet.account(), nearConfig.contractName as string, {
    viewMethods: [
      "getRooms",
      "getRoomMembers",
      "getRoomRequests",
      "getRoomGames",
      "getRoom",
      "getProfile",
      "getGamePlayers",
      "getGameStakers",
      "getWinner",
      "getGame",
    ],
    changeMethods: ["createRoom", "joinPublicRoom", "requestToJoinPrivateRoom", "responseToRequest", "createGame", "play", "stake", "payout"],
  });

  return { contract, currentUser, nearConfig, wallet };
};

export default initContract;
