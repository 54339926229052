import { IUser } from "./../model/index";
import makeRequest from "config/utils/makeRequest";
import { thunk, action } from "easy-peasy";
import { IUserModel } from "store/model";

const basePath = "/user";

export const user: IUserModel = {
  loading: false,
  loggedIn: false,
  currentUser: null,
  addUserInfo: thunk(async (actions, { payload, cb }) => {
    actions.setLoading(true);
    try {
      await makeRequest("POST", basePath + "/", payload);
      cb && cb();
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),
  getUserInfo: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { user },
        },
      } = await makeRequest("GET", basePath + "/");
      actions.saveCurrentUser(user as IUser);
      actions.setLoggedIn(true);
    } catch (error: any) {
      payload?.err && payload.err(error);
    } finally {
      actions.setLoading(false);
    }
  }),
  saveCurrentUser: action((state, payload) => {
    state.currentUser = payload;
  }),
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  setLoggedIn: action((state, payload) => {
    state.loggedIn = payload;
  }),
};
