import { IExperience } from "pages/InfoUpdate";
import { IWorkHistory } from "./../model/index";
import makeRequest from "config/utils/makeRequest";
import { thunk, action } from "easy-peasy";
import { IWorkHistoryModel } from "store/model";

const basePath = "/work-history";

export const workHistory: IWorkHistoryModel = {
  loading: false,
  item: null,
  items: [],
  getItem: thunk(async (actions, id) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { workHistory },
        },
      } = await makeRequest("GET", basePath + `/${id}`);

      actions.saveItem(workHistory as IWorkHistory);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),
  getItems: thunk(async (actions) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { workHistory },
        },
      } = await makeRequest("GET", basePath + "/");

      actions.saveItems(workHistory);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),
  softUpdateItems: thunk(async (actions) => {
    try {
      const {
        data: {
          data: { workHistory },
        },
      } = await makeRequest("GET", basePath + "/");

      actions.saveItems(workHistory);
    } catch (error) {}
  }),
  addHistory: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      await makeRequest("POST", basePath + "/", payload);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  deleteHistory: thunk(async (actions, id) => {
    try {
      await makeRequest("DELETE", basePath + `/${id}`);
    } catch (error) {
    } finally {
    }
  }),

  editHistory: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      await makeRequest("PATCH", basePath + "/" + payload._id, payload as IExperience);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  getItemsByStatus: thunk(async (actions, payload) => {
    actions.setLoading(true);
    try {
      const {
        data: {
          data: { workHistory },
        },
      } = await makeRequest("GET", basePath + "/" + payload);

      actions.saveItems(workHistory);
    } catch (error) {
    } finally {
      actions.setLoading(false);
    }
  }),

  saveItem: action((state, payload) => {
    state.item = payload;
  }),

  saveItems: action((state, payload) => {
    state.items = payload;
  }),

  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
};
