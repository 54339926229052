import { validation } from "./state/validation";
import { workHistory } from "./state/workHistory";
import { user } from "./state/user";
import { createStore } from "easy-peasy";
import { StoreModel } from "./model";

const storeModel: StoreModel = {
  user,
  workHistory,
  validation,
};
const store = createStore(storeModel);

export default store;
