import styled from "styled-components";
import { SpacingStyle } from "./spacing";
import { ColProps } from "./types";

export const GridContainerStyle = styled(SpacingStyle)<ColProps>`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-right: -15px;
  margin-left: -15px;
  row-gap: 30px;
`;

export const GridStyle = styled(SpacingStyle)<ColProps>`
  padding-right: 15px;
  padding-left: 15px;
  width: 100%;
  overflow: visible;
  ${({ theme, xs }) =>
    xs
      ? ` ${theme.mediaQueries.min.xs}{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(xs * 100) / 12}%;
    -ms-flex: 0 0 ${(xs * 100) / 12}%;
    flex: 0 0 ${(xs * 100) / 12}%;
    max-width: ${(xs * 100) / 12}%;
  }`
      : ""}

  ${({ theme, sm }) =>
    sm
      ? ` ${theme.mediaQueries.min.sm}{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(sm * 100) / 12}%;
    -ms-flex: 0 0 ${(sm * 100) / 12}%;
    flex: 0 0 ${(sm * 100) / 12}%;
    max-width: ${(sm * 100) / 12}%;
  }`
      : ""}


  ${({ theme, md }) =>
    md
      ? ` ${theme.mediaQueries.min.md}{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(md * 100) / 12}%;
    -ms-flex: 0 0 ${(md * 100) / 12}%;
    flex: 0 0 ${(md * 100) / 12}%;
    max-width: ${(md * 100) / 12}%;
  }`
      : ""}

  ${({ theme, lg }) =>
    lg
      ? ` ${theme.mediaQueries.min.lg}{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(lg * 100) / 12}%;
    -ms-flex: 0 0 ${(lg * 100) / 12}%;
    flex: 0 0 ${(lg * 100) / 12}%;
    max-width: ${(lg * 100) / 12}%;
  }`
      : ""}
  
  ${({ theme, xl }) =>
    xl
      ? ` ${theme.mediaQueries.min.xl}{
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(xl * 100) / 12}%;
    -ms-flex: 0 0 ${(xl * 100) / 12}%;
    flex: 0 0 ${(xl * 100) / 12}%;
    max-width: ${(xl * 100) / 12}%;
  }`
      : ""}

            
${({ col }) =>
    col
      ? `
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 ${(col * 100) / 12}%;
    -ms-flex: 0 0 ${(col * 100) / 12}%;
    flex: 0 0 ${(col * 100) / 12}%;
    max-width: ${(col * 100) / 12}%;
  `
      : ""}
`;
